import React, {useState, useEffect} from 'react';
import compose from 'lodash/flowRight';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {getWidgetsByDashboard} from '../../api/dashboards';
import withSnackbar from '../../shared/components/snackbarSupport';
import WidgetMapping from './widgets';

const DashboardApp = ({
  getWidgetsByDashboardApi,
  snackbar,
  dashboardId,
  reload,
  currentUser,
  filtering,
  pillar,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);
  useEffect(
    () => {
      let isMounted = true; // Flag to track mount status

      const fetchDisplay = async () => {
        try {
          const {results} = await getWidgetsByDashboardApi(dashboardId, pillar);
          if (isMounted) {
            setWidgets(results);
            setLoading(false);
          }
        } catch (e) {
          if (isMounted) {
            snackbar.error(
              `${e}, There was an issue retrieving the widgets. If this issue persists please contact support for assistance`,
            );
            setLoading(false);
          }
        }
      };

      fetchDisplay();

      // Cleanup function
      return () => {
        isMounted = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reload],
  );

  return (
    <Grid item xs={12} {...rest}>
      <Grid
        container
        columnSpacing={{xs: 0.5, sm: 2, md: 2, lg: 3, xl: 3}}
        rowSpacing={{xs: 1, sm: 2, md: 2, lg: 3, xl: 3}}
        marginBottom={(theme) => theme.spacing(2)}
      >
        {widgets.map(
          (widget) =>
            widget.enabled && (
              <WidgetMapping
                key={widget.position}
                widgetConfig={widget}
                loading={loading}
                filtering={filtering}
              />
            ),
        )}
      </Grid>
    </Grid>
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(DashboardApp);

DashboardApp.propTypes = {
  getWidgetsByDashboardApi: PropTypes.func,
};

DashboardApp.defaultProps = {
  getWidgetsByDashboardApi: getWidgetsByDashboard,
};
