import React from 'react';
import {Link} from '@mui/material';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import InsightsFilter from '../../../../pages/reports/newReportsPage/reportFilters/filterTypes/InsightsFilter';

const SalesInsightsHeaderWidget = ({
  currentUser,
  showButton,
  widgetConfig,
  filtering,
}) => {
  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'Sales Insights'}
      subtitle={widgetConfig.description || ''}
      actions={
        showButton && [
          {
            id: 'link-button',
            label: 'See full Intelligence Page',
            ButtonProps: {component: Link, href: '/ui/reports'},
          },
        ]
      }
      filters={
        currentUser.settings.insightsDateSiteFilter && filtering ? (
          <InsightsFilter filtering={filtering} />
        ) : null
      }
      {...widgetConfig.properties}
    />
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(SalesInsightsHeaderWidget);
