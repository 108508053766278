import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import HighChartsWidget from '../../../../shared/components/highcharts';
import {getSiteByDayDiscountStats} from '../../../../api/reports';
import * as discountDollars from './widgetUtils/discountDollarsUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

const DiscountDollarsWidget = ({currentUser, widgetConfig, filtering}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;
  const theme = useTheme();

  const subtitle = () => {
    if (filtering?.filters) {
      const offsetDaysFilter =
        filtering.filters[
          filtering.filters
            .map((element) => element.paramName)
            .indexOf('offsetDays')
        ];
      if (offsetDaysFilter && offsetDaysFilter.value !== 7) {
        return null;
      }
    }

    return (
      widgetConfig.description ||
      'Discount dollars as a percentages of sales over the last seven days.'
    );
  };

  const getContent = () => {
    return (
      <HighChartsWidget
        id="highcharts-widget-discounts"
        getData={getSiteByDayDiscountStats}
        params={{
          filters: filtering?.filters,
          reportLookBackDays,
          currentUserDate,
        }}
        formatData={discountDollars.formatData}
        tooltipProvider={discountDollars.formatTooltip}
        graphSettings={{
          tipText: 'Discount Dollars:',
          chartType: 'column',
          seriesName: 'Discounts',
          title:
            widgetConfig.name || 'Discount Dollars as a Percentage of Sales',
          subtitle: subtitle(),
          plotLinesLabel: 'avg discount dollars % sale',
          xAxisTitle: '',
          yAxisTitle: '',
          columnColor: theme.palette.primary.main,
          hoverColor: theme.palette.primary.light,
          widgetName: 'discounts-widget',
        }}
        extraOptions={discountDollars.extraOptions}
      />
    );
  };
  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(DiscountDollarsWidget);
