import React, {useEffect, Fragment} from 'react';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getCamerasHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';

const CameraWidget = ({widgetConfig, currentUser}) => {
  const [camerasHealthSummary, loading, isError, getCamerasSummary] = useApi(
    getCamerasHealthSummary,
  );

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getCamerasSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getCamerasSummary],
  );

  const count = camerasHealthSummary?.offline;
  const offlineChanges = camerasHealthSummary?.offlineChanges;
  const impairedCount = camerasHealthSummary?.impaired;
  const message = `${offlineChanges} Offline in the last 24 hrs.`;

  return (
    <Grid item id={widgetConfig.widgetReference} {...widgetConfig.properties}>
      <Card
        sx={{
          p: 2,
          height: '220px',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        {loading ? (
          <CircularProgress size={50} sx={{m: 'auto'}} />
        ) : (
          <Fragment>
            {isError ? (
              <Alert id="error-cameras" severity="error" sx={{width: '100%'}}>
                Error loading widget.
              </Alert>
            ) : (
              <Fragment>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '50%',
                    borderRight: '1px solid gainsboro',
                  }}
                >
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h6">Cameras Offline</Typography>
                  </Box>
                  <Typography
                    id="offline-cameras"
                    sx={{fontSize: '60px', lineHeight: 1.2, mt: 2}}
                  >
                    {count}
                  </Typography>
                  <Button
                    sx={{p: 0}}
                    component={Link}
                    data-cy="camera-status-offline"
                    to={{
                      pathname: isNewNavigationWebEnabled(currentUser)
                        ? '/home/system-health'
                        : '/hardware-health',
                      search: '?cameraStatus=offline',
                      state: {fromDashboard: true},
                    }}
                    target={openInOneTab(currentUser)}
                  >
                    Offline
                  </Button>
                  <Typography
                    id="last-day-offline-cameras"
                    sx={{textAlign: 'center', mt: 2, overflow: 'hidden'}}
                  >
                    {message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '50%',
                  }}
                >
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h6" sx={{ml: 3}}>
                      Image Health
                    </Typography>
                    <VideocamOutlined />
                  </Box>
                  <Typography
                    id="impaired-cameras"
                    sx={{fontSize: '60px', lineHeight: 1.2, mt: 2}}
                  >
                    {impairedCount}
                  </Typography>
                  <Button
                    sx={{p: 0}}
                    component={Link}
                    data-cy="camera-status-impaired"
                    to={{
                      pathname: isNewNavigationWebEnabled(currentUser)
                        ? '/home/system-health'
                        : '/hardware-health',
                      search: '?impairedCameras=impaired',
                      state: {fromDashboard: true},
                    }}
                    target={openInOneTab(currentUser)}
                  >
                    Impaired
                  </Button>
                </Box>
              </Fragment>
            )}
          </Fragment>
        )}
      </Card>
    </Grid>
  );
};

export default withCurrentUser(CameraWidget);
