import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Checkbox,
  Typography,
} from '@mui/material';
import {uniq} from 'lodash';

import {manageSitesToScheduleTemplate} from '../../../../api/alarms';

import CallbackFilterField from '../../../../shared/components/callbackFilterField';
import {handleFilterChange} from '../filterReducer';
import {useSiteManagementDialog} from './hooks';
import PagedList from '../../../../apps/sitesCategoriesList/PagedList';
import Spinner from '../../../../shared/components/spinner';
import {useDialog} from '../../../../shared/hooks';
import {checkboxAllStatus} from './utils';

const ManageSitesDialog = ({
  dialogOpen,
  onDialogClose,
  selectedSchedule,
  snackbar,
  totalSSPSitesCount,
  isMediumScreen,
  onTableRefresh,
}) => {
  const {
    siteList,
    handleLoadMoreData,
    selectedSites,
    disabledSubmit,
    handleCheckboxSelect,
    handleSelectAll,
    resetState,
    searchValue,
    isSearchValueValid,
    dispatch,
    statuses,
    unprocessableSites,
    setUnprocessableSites,
    conflictedSchedules,
    isFirstPage,
    isSelectAllLoading,
    isOnePaged,
    changesRef,
    selectAllCheckboxStatus,
  } = useSiteManagementDialog(selectedSchedule, totalSSPSitesCount);

  const {isLoadingStatus: isConflictsLoading, conflicts} = conflictedSchedules;
  const [isSaving, setIsSaving] = useState(false);
  const {dataLoading, dataReloading, dataFetched, dataFetchError} = statuses;
  const [conflictedSites, setConflictedSites] = useState({
    conflictedSites: [],
    data: null,
  });
  const [
    conflictedSitesDialogOpen,
    handleConflictedSitesDialogOpen,
    handleConflictedSitesDialogClose,
  ] = useDialog();
  const [
    unprocessableSitesDialogOpen,
    handleUnprocessableSitesDialogOpen,
    handleUnprocessableSitesDialogClose,
  ] = useDialog();

  const handleClose = () => {
    dispatch({type: 'RESET_SEARCH'});
    onDialogClose();
    resetState();
  };

  const handleSave = async (scheduleId, conflictsList = []) => {
    // Filter out conflicted sites if provided
    const {set, unset} = changesRef.current;
    let sitesToSetWithoutConflicts = [...set];
    if (conflictsList.length) {
      sitesToSetWithoutConflicts = set.filter(
        ({siteId}) => !conflictsList.find((id) => siteId === id),
      );
    }
    const body = {
      set: {sites_ids: sitesToSetWithoutConflicts.map(({siteId}) => siteId)},
      unset: {sites_ids: unset.map(({siteId}) => siteId)},
    };

    setIsSaving(true);
    try {
      await manageSitesToScheduleTemplate(scheduleId, body);
      snackbar.success('Sites have been successfully managed.');
      onTableRefresh((prev) => !prev);
      handleClose();
      resetState();
    } catch (error) {
      const {response} = error;
      if (response?.status === 422 && response.data.error?.unprocessableSites) {
        setUnprocessableSites(response.data.error.unprocessableSites);
        handleUnprocessableSitesDialogOpen();
      } else if (
        response?.status === 409 &&
        response.data.error?.conflictData
      ) {
        setConflictedSites({
          conflictedSites: uniq(
            response.data.error.conflictData.map(({siteId}) => siteId),
          ),
          data: {scheduleId},
        });
        handleConflictedSitesDialogOpen();
      } else {
        snackbar.error('Failed to manage sites.');
        handleClose();
        resetState();
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleSitesAssignmentWithConflicts = async () => {
    await handleSave(
      conflictedSites.data.scheduleId,
      conflictedSites.conflictedSites,
    );
    handleConflictedSitesDialogClose();
  };

  const handleShowingSiteCategoriesList = () => {
    const shouldShowList = dataFetched || dataLoading || !dataReloading;
    if (isFirstPage && !searchValue && !isSelectAllLoading) {
      return siteList.length > 0 && !dataReloading && !isConflictsLoading;
    }
    return shouldShowList;
  };

  const sortedConflictedSitesWithName = conflictedSites.conflictedSites
    ?.map((id) => {
      const site = selectedSites?.find((s) => s.siteId === id);
      return {siteId: id, name: site?.name};
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Fragment>
      <Dialog
        maxWidth="lg"
        fullWidth
        fullScreen={isMediumScreen}
        PaperProps={{sx: {height: !isMediumScreen ? '80vh' : '100%'}}}
        data-cy="manage-site-dialog"
        open={dialogOpen}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            data-cy="select-all-checkbox"
            onChange={({target}) => handleSelectAll(target.checked)}
            color="primary"
            disabled={
              !siteList.length ||
              (siteList.length === conflicts.length && isOnePaged) ||
              dataReloading ||
              dataLoading ||
              isConflictsLoading
            }
            checked={selectAllCheckboxStatus === checkboxAllStatus.Checked}
            indeterminate={
              selectAllCheckboxStatus === checkboxAllStatus.Indeterminate
            }
          />
          <CallbackFilterField
            sx={{margin: 0}}
            size="small"
            data-cy="manage-site-search"
            isDebounced
            filterValue={searchValue}
            onFilter={(value) => handleFilterChange(dispatch, value)}
            error={!isSearchValueValid}
            helperText={
              isSearchValueValid ? null : 'Please enter at least 3 characters'
            }
            variant="outlined"
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{paddingTop: 0}}>
          <PagedList
            list={siteList}
            hasFilter={!!siteList}
            showSiteCategoryList={handleShowingSiteCategoriesList()}
            showProgress={
              dataLoading ||
              dataReloading ||
              isConflictsLoading ||
              isSelectAllLoading
            }
            showError={dataFetchError}
            showMoreButton={
              !(dataLoading || dataReloading || isConflictsLoading) &&
              !isOnePaged
            }
            disabledMoreButton={dataLoading}
            dropdownNav
            checkboxListMode
            onCheckboxSelect={(site, e) => handleCheckboxSelect(site, e)}
            handleLoadMore={() => handleLoadMoreData()}
            disableSiteDetails
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              handleSave(selectedSchedule.id, conflictedSites.conflictedSites)
            }
            disabled={disabledSubmit || dataLoading || isSaving}
          >
            Save
            {isSaving && <Spinner style={{marginLeft: '10px'}} size={15} />}
          </Button>
        </DialogActions>
      </Dialog>
      {unprocessableSitesDialogOpen && unprocessableSites.length > 0 && (
        <Dialog
          maxWidth="sm"
          PaperProps={{sx: {height: '40vh'}}}
          data-cy="assignment-error"
          open={unprocessableSitesDialogOpen}
          onClose={handleUnprocessableSitesDialogClose}
        >
          <DialogTitle
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Assignment Error
          </DialogTitle>
          <DialogContent sx={{height: '100%', overflow: 'auto'}}>
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Schedule Exception cannot be assign to sites with shared Alarm
              Schedule, if not all of those sites are selected.
            </Typography>
            <br />
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Please unselect or change the Alarm Schedule for fallowing site(s)
              before Saving:
            </Typography>
            <br />
            <Typography
              variant="subtitle2"
              sx={{textAlign: 'center', whiteSpace: 'pre-line'}}
            >
              {unprocessableSites
                ?.map(({name}, ind) => `${ind + 1}. ${name}`)
                ?.join('\n')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleUnprocessableSitesDialogClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {conflictedSitesDialogOpen &&
        conflictedSites.conflictedSites?.length > 0 && (
          <Dialog
            maxWidth="sm"
            PaperProps={{sx: {height: '40vh'}}}
            data-cy="conflicted-sites-dialog"
            open={conflictedSitesDialogOpen}
            onClose={handleConflictedSitesDialogClose}
          >
            <DialogTitle
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Conflict
            </DialogTitle>
            <DialogContent sx={{height: '100%', overflow: 'auto'}}>
              <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
                There
                {conflictedSites.conflictedSites.length === 1
                  ? ' is '
                  : ' are '}
                {conflictedSites.conflictedSites.length} site
                {conflictedSites.conflictedSites.length > 1 ? 's' : ''} that can
                not be assigned to the template
              </Typography>
              <br />
              <Typography
                variant="subtitle2"
                sx={{textAlign: 'center', whiteSpace: 'pre-line'}}
              >
                {sortedConflictedSitesWithName
                  ?.map(
                    ({name}, ind) =>
                      `${ind + 1}. ${name || 'Invalid conflicted site data'}`,
                  )
                  ?.join('\n')}
              </Typography>
              <br />
              <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
                Would you like to continue?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  setConflictedSites({
                    conflictedSites: [],
                    data: null,
                  });
                  handleConflictedSitesDialogClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSitesAssignmentWithConflicts}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
    </Fragment>
  );
};

ManageSitesDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  selectedSchedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  snackbar: PropTypes.shape({}).isRequired,
  isMediumScreen: PropTypes.bool.isRequired,
  onTableRefresh: PropTypes.func.isRequired,
  totalSSPSitesCount: PropTypes.number,
};
ManageSitesDialog.defaultProps = {
  selectedSchedule: undefined,
  totalSSPSitesCount: undefined,
};
export default ManageSitesDialog;
