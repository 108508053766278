import React, {useEffect, Fragment} from 'react';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

import DvrIcon from '@mui/icons-material/Dvr';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getAppliancesHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';

const ApplianceWidget = ({widgetConfig, currentUser}) => {
  const [
    applianceHealthSummary,
    loading,
    isError,
    getApplianceHealthSummary,
  ] = useApi(getAppliancesHealthSummary);

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getApplianceHealthSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getApplianceHealthSummary],
  );

  const count = applianceHealthSummary?.offline;
  const offlineChanges = applianceHealthSummary?.offlineChanges;
  const message = `${offlineChanges} Offline in the last 24 hrs.`;

  return (
    <Grid item id={widgetConfig.widgetReference} {...widgetConfig.properties}>
      <Card
        sx={{
          p: 2,
          height: '220px',
          display: 'flex',
        }}
      >
        {loading ? (
          <CircularProgress size={50} sx={{m: 'auto'}} />
        ) : (
          <Box sx={{width: '100%'}}>
            {isError ? (
              <Alert id="error-appliances" severity="error">
                Error loading widget.
              </Alert>
            ) : (
              <Fragment>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6">Appliances</Typography>
                  <DvrIcon />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    m: 2,
                  }}
                >
                  <Typography
                    id="offline-appliances"
                    sx={{fontSize: '60px', lineHeight: 1.2}}
                  >
                    {count}
                  </Typography>
                  <Button
                    sx={{p: 0}}
                    component={Link}
                    data-cy="appliance-status-offline"
                    to={{
                      pathname: isNewNavigationWebEnabled(currentUser)
                        ? '/home/system-health'
                        : '/hardware-health',
                      search: '?status=offline,impaired',
                      state: {fromDashboard: true},
                    }}
                    target={openInOneTab(currentUser)}
                  >
                    Offline
                  </Button>
                </Box>
                <Typography
                  id="last-day-offline-appliances"
                  sx={{textAlign: 'center'}}
                >
                  {message}
                </Typography>
              </Fragment>
            )}
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default withCurrentUser(ApplianceWidget);
