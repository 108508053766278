import React, {Fragment, useCallback} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Link,
  ListItemSecondaryAction,
  Button,
  useMediaQuery,
  Box,
  Alert,
} from '@mui/material';
import CameraListRow from './CameraListRow';
import {isMobileApp} from '../../../../shared/util/general';

const CamerasList = (props) => {
  const {
    site,
    onCameraEnableClick,
    siteHasSchedule,
    onCameraEdit,
    camerasList,
    setCamerasList,
    isVideoAlarmSupported,
    isEnvrAdmin,
    allowConfig,
  } = props;

  const handleOnCheckboxClick = async (camera) => {
    await onCameraEnableClick(camera);
    if (camera.motionAlarmEnabled) {
      setCamerasList(
        camerasList.map((cam) => {
          if (cam.id === camera.id) {
            return {
              ...cam,
              motionAlarmEnabled: false,
            };
          }
          return cam;
        }),
      );
    }
  };
  const getWarningContent = () => {
    let content;
    if (!isVideoAlarmSupported) {
      content = (
        <Typography>
          The appliance software version does not support video alarms or is
          unknown, please contact support for assistance.
        </Typography>
      );
    }
    if (isVideoAlarmSupported && camerasList.length === 0) {
      content = (
        <Typography>
          No Cameras enabled for this site. Visit the{' '}
          {isEnvrAdmin ? (
            <Link
              component={RouterLink}
              to={`/sites/${site.id}/settings/devices`}
            >
              Device Management
            </Link>
          ) : (
            <span>Device Management</span>
          )}{' '}
          page to enable cameras for display here.
        </Typography>
      );
    }
    return content;
  };
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const mobileApp = isMobileApp();

  const displaySubtext = useCallback(
    () => {
      if (!allowConfig) {
        return null;
      }
      if (mobileApp) {
        return (
          <Alert data-cy="camera-enablement-small-screen-alert" severity="info">
            Camera Enablement is only available on desktop
          </Alert>
        );
      }

      if (smallScreen) {
        return (
          <Alert
            data-cy="camera-enablement-small-screen-desktop-alert"
            severity="info"
          >
            Increase the size of the window to view and edit camera settings
          </Alert>
        );
      }
      return (
        <Typography data-cy="camera-enablement-subtext" pb={2} variant="body2">
          Enabling a camera could take up to 5 minutes where you cannot navigate
          away from the page
        </Typography>
      );
    },
    [smallScreen, mobileApp, allowConfig],
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
      }}
      name="cameras-card"
    >
      {!isVideoAlarmSupported || camerasList.length === 0 ? (
        getWarningContent()
      ) : (
        <Fragment>
          {displaySubtext()}
          <Grid
            container
            justifyContent="space-between"
            paddingTop={1}
            paddingBottom={0.5}
          >
            {!(smallScreen || mobileApp) && (
              <Fragment>
                <Grid item md={2}>
                  <Typography variant="body1">Enabled</Typography>
                </Grid>
                <Grid item md={7}>
                  <Typography px={2} variant="body1">
                    Camera Name
                  </Typography>
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Box
            name="cameras-list"
            sx={{display: 'grid', gridAutoRows: '1fr', gridAutoFlow: 'row'}}
          >
            {camerasList.map((camera) => {
              const cameraWithImg = {
                ...camera,
                img: `/api/v3/sensor_views/${camera.id}/thumbnail`,
              };
              return (
                <CameraListRow
                  smallScreen={smallScreen || mobileApp}
                  key={`camera-${camera.id}`}
                  checked={camera.motionAlarmEnabled}
                  camera={cameraWithImg}
                  onChange={() => handleOnCheckboxClick(camera)}
                  disableSelect={
                    !allowConfig || !siteHasSchedule || smallScreen || mobileApp
                  }
                >
                  {camera.motionAlarmEnabled && !(smallScreen || mobileApp) && (
                    <Grid item md={1}>
                      <ListItemSecondaryAction>
                        <Button
                          color="primary"
                          variant="outlined"
                          name={`camera-${camera.id}-edit`}
                          disabled={!allowConfig}
                          onClick={() => {
                            onCameraEdit(camera);
                          }}
                        >
                          EDIT
                        </Button>
                      </ListItemSecondaryAction>
                    </Grid>
                  )}
                </CameraListRow>
              );
            })}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
CamerasList.propTypes = {
  isEnvrAdmin: PropTypes.bool.isRequired,
  onCameraEnableClick: PropTypes.func.isRequired,
  siteHasSchedule: PropTypes.bool.isRequired,
  onCameraEdit: PropTypes.func.isRequired,
  camerasList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCamerasList: PropTypes.func.isRequired,
  isVideoAlarmSupported: PropTypes.bool.isRequired,
  allowConfig: PropTypes.bool.isRequired,
};

export default CamerasList;
