import {
  createTheme,
  responsiveFontSizes,
  darken,
  useTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cloneDeep from 'lodash/cloneDeep';
import update from 'lodash/update';
import merge from 'lodash/merge';

import {amber, grey} from '@mui/material/colors';
import {hideGlobalMenu} from '../util/general';

// https://mui.com/material-ui/react-use-media-query/#migrating-from-withwidth
// https://github.com/mui/material-ui/blob/v5.13.5/docs/data/material/components/use-media-query/UseWidth.js
export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

const BREAKPOINTS = {
  XL: 1920,
  LG: 1280,
  MD: 960,
  SM: 600,
};

export const withWidth = () => {
  const deviceWidth = window.innerWidth;

  if (deviceWidth >= BREAKPOINTS.XL) {
    return 'xl';
  }
  if (deviceWidth >= BREAKPOINTS.LG) {
    return 'lg';
  }
  if (deviceWidth >= BREAKPOINTS.MD) {
    return 'md';
  }
  if (deviceWidth >= BREAKPOINTS.SM) {
    return 'sm';
  }
  return 'xs';
};

const bottomNavigationBar = {
  height: 56,
};

const toolbar = {
  height: 54,
};

const footer = {
  height: 40,
};

const sitePicker = {
  height: 88, // 88px is height of site picker + margins from new navigation summary
};

const sidebar = {
  sidebarTopPadding: {
    height: 24,
  },
  sidebarChevronButton: {
    height: 64,
  },
};

const minHeightUpdater = (n) =>
  hideGlobalMenu() ? '100vh' : `calc(100vh - ${n}px)`;
const viewport = cloneDeep(toolbar);
update(viewport, 'height', minHeightUpdater);
Object.keys(viewport)
  .filter((k) => k.startsWith('@media'))
  .forEach((k) => update(viewport, `${k}.height`, minHeightUpdater));

/**
 * Default theme values are here: https://material-ui-next.com/customization/default-theme/
 * Theme customization: https://material-ui-next.com/customization/themes/
 * Handy color tool: http://www.color-hex.com
 */

const baseThemeSettings = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  timing: {
    tooltip: {
      enterDelay: 250,
    },
  },
  palette: {
    default: {
      light: '#ebebeb',
      main: '#e0e0e0',
      dark: '#d5d5d5',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    primary: {
      lightest: '#edf8fe',
      light: '#4fa3cd',
      main: '#248dc1',
      dark: '#196287',
      contrastText: '#fff',
      hover: '#248dc10a',
    },
    secondary: {
      light: '#cf539b',
      main: '#c42983',
      dark: '#891c5b',
      contrastText: '#fff',
    },
    accent: {
      light: '#ffac33',
      main: '#ff9801',
      dark: '#b26a00',
    },
    error: {
      light: '#cb5e3c',
      main: '#BF360C',
      dark: '#852508',
      contrastText: '#fff',
    },
    success: {
      light: '#68b36b',
      main: '#43a047',
      dark: '#2e7031',
    },
    warning: {
      dark: amber[700],
      main: amber[400],
      light: amber[100],
      contrastText: '#000',
      darker: '#5F2B01',
      fill: '#FDF0E6',
    },
    background: {
      default: '#fafafa',
      transparent: 'rgba(0,0,0,.65)',
      fail: '#fbeaea',
    },
    graphColor: {
      100: '#248dc1',
      200: '#c42983',
      300: '#ff9801',
      400: '#BF360C',
      500: amber[400],
    },
  },
  video: {
    transitions: {
      fadeIn: {
        transition: 'opacity .1s',
        opacity: 1,
      },
      fadeOut: {
        transition: 'opacity 1s',
        opacity: 0,
      },
    },
    palette: {
      background: grey[800],
      primary: 'white',
    },
    controlBar: {
      palette: {
        primary: '#fff',
        disabled: `${darken('#fff', 0.45)}!important`,
        motionMaskPlayHead: '#FFFB0A',
      },
      height: 30,
      buttonWidth: 40,
    },
  },
  mixins: {
    bottomNavigationBar,
    toolbar,
    viewport,
    footer,
    sitePicker,
    sidebar,
    drawer: {
      position: 'relative',
      width: 240,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    scrollable: (additionalHeight = 0) => ({
      height: `calc(100vh - ${toolbar.height * 2 + additionalHeight}px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
    }),
  },
  typography: {
    useNextVariants: true,
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: '3rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'text'},
          style: {color: 'primary'},
        },
        {
          props: {variant: 'contained'},
          style: {color: 'primary'},
        },
        {
          props: {variant: 'outlined'},
          style: {color: 'primary'},
        },
      ],
    },
  },
};
export const baseTheme = responsiveFontSizes(createTheme(baseThemeSettings));

export const useThemeFont = (element) => {
  // Used to pass in a Typography variant, ie. h1-6, body, etc.
  // Which will return the corresponding responsive MUI theme css for said variant
  const theme = useTheme();
  const size = withWidth();
  const elementStyle = theme.typography[element];
  const mediaQuery = `@media (min-width:${
    baseThemeSettings.breakpoints.values[size]
  }px)`;
  const mediaQueryStyles = elementStyle[mediaQuery];
  return {
    ...elementStyle,
    ...mediaQueryStyles,
  };
};

export const clipsTheme = createTheme(
  Object.assign(baseThemeSettings, {
    typography: {
      fontFamily: ['Open Sans, Arial, Helvetica, sans-serif'].join(','),
      fontWeight: '600',
      useNextVariants: true,
    },
    palette: Object.assign(baseThemeSettings.palette, {
      primary: {
        lightest: '#edf8fe',
        light: '#0087e9',
        main: '#0087e9',
        dark: '#196287',
        contrastText: '#fff',
      },
      secondary: {
        lightest: '#edf8fe',
        light: '#0087e9',
        main: '#0087e9',
        dark: '#196287',
        contrastText: '#fff',
      },
      grey: {
        100: '#d8d8d8',
        800: '#333333',
      },
    }),
    components: {
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontSize: '.9rem',
          },
          h5: {
            color: '#333333',
            fontWeight: 800,
            fontSize: '18px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            padding: '.6em',
            border: [[1, 'solid']],
            fontSize: '14px',
            borderColor:
              'rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)',
            textTransform: 'none',
            minWidth: '6em',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: [[1, 1, 5, 'rgba(0, 0, 0, 0.2)']],
              transform: 'translateY(-1px)',
            },
            '&$disabled': {
              backgroundColor: '#888888 !important',
              color: '#ffffff !important',
            },
          },
          contained: {
            borderRadius: 4,
            padding: [[6, 12]],
            marginLeft: '1em',
            border: [[1, 'solid']],
            borderColor:
              'rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)',
            textTransform: 'none',
            color: '#0087e9',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: [[1, 1, 5, 'rgba(0, 0, 0, 0.2)']],
              transform: 'translateY(-1px)',
            },
          },
        },
      },
    },
  }),
);

export const sspTheme = (theme) =>
  createTheme({
    ...merge(theme, {
      palette: {
        error: {
          light: '#F35449',
        },
        success: {
          light: '#69B36C',
        },
        warning: {
          main: '#FFC107',
        },
      },
    }),
  });

export const responsiveTheme = (theme) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      nav: {
        security: 'rgba(249, 129, 43, 1)',
        incidents: 'rgba(0, 131, 124, 1)',
        intelligence: 'rgba(63, 81, 181, 1)',
        operations: 'rgba(46, 111, 64, 1)',
      },
    },
    components: {
      ...theme.components,
      MuiDialog: {
        defaultProps: {
          maxWidth: 'md',
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 720,
        lg: 1280,
        xl: 1920,
      },
    },
  });
