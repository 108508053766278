import React from 'react';
import PropTypes from 'prop-types';
import {Chip} from '@mui/material';

const variants = Object.freeze({
  active: {color: 'success', label: 'Active'},
  eventsOnly: {color: 'primary', label: 'Events Only'},
  test: {color: 'warning', label: 'Test'},
  inactive: {color: 'error', label: 'Inactive'},
});

const ButtonModeChip = ({mode, customLabel}) => {
  const {color, label} = variants[mode] || variants.active;
  return (
    <Chip
      label={customLabel || label}
      variant="outlined"
      size="small"
      color={color}
    />
  );
};

ButtonModeChip.propTypes = {
  mode: PropTypes.oneOf(['active', 'eventsOnly', 'test', 'inactive'])
    .isRequired,
  customLabel: PropTypes.string,
};

ButtonModeChip.defaultProps = {
  customLabel: '',
};

export default ButtonModeChip;
