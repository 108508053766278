import {Button, Grid, Paper, styled, useMediaQuery} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, {useState} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import {dateRange} from '../../../../smartSiteProtection/landingPage/dateRangeUtils';
import {withCurrentUser} from '../../../../../shared/contexts/currentUserContext';
import SiteHierarchyFilter from './SiteHierarchyFilter';
import {getFilterByParamName} from './FilterUtils';

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
  },
}));

const InsightsFilter = ({filtering}) => {
  const {filters, setFilters} = filtering;
  const [currentFilters, setCurrentFilters] = useState(filters);

  const filterButton = ({days, displayText}) => (
    <StyledToggleButton value={days}>{displayText}</StyledToggleButton>
  );

  const onApplyChanges = () => setFilters(currentFilters);

  const updateIndex = (index, newValue) => {
    setCurrentFilters((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        value: newValue,
      };
      return newState;
    });
  };

  const onSiteChange = (site) =>
    updateIndex(getFilterByParamName(currentFilters, 'sites').index, site);
  const onDateChange = (_event, newSelectedDuration) =>
    updateIndex(
      getFilterByParamName(currentFilters, 'offsetDays').index,
      newSelectedDuration,
    );
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      id="sales-insight-filters"
      container
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={12} md={6} lg={6}>
        <ToggleButtonGroup
          id="offset-days-filter"
          fullWidth
          value={getFilterByParamName(currentFilters, 'offsetDays').value}
          exclusive
          onChange={onDateChange}
          aria-label="date-range-filter"
          size="medium"
          sx={{
            backgroundColor: 'white',
          }}
        >
          {filterButton(dateRange.last90Days)}
          {filterButton(dateRange.last30Days)}
          {filterButton(dateRange.last14Days)}
          {filterButton(dateRange.last7Days)}
          {filterButton(dateRange.last24Hours)}
        </ToggleButtonGroup>
      </Grid>
      <Grid id="site-hierarchy-filter" item sm={10} xs={9} md={5} lg={5}>
        <Paper sx={{width: 'fit-width'}}>
          <SiteHierarchyFilter
            filter={getFilterByParamName(currentFilters, 'sites')}
            siteHierarchyData={
              getFilterByParamName(currentFilters, 'sites').data
            }
            onChange={onSiteChange}
            isSmallScreen={isSmallScreen}
          />
        </Paper>
      </Grid>

      <Grid
        id="apply-filter"
        item
        container
        sm={2}
        xs={3}
        md={1}
        lg={1}
        justifyContent="flex-end"
      >
        <Button
          sx={{marginRight: 1}}
          variant="contained"
          disabled={currentFilters.length === 0}
          onClick={() => {
            onApplyChanges();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default withCurrentUser(InsightsFilter);
