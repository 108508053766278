import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {Typography} from '@mui/material';
import {differenceBy, isEmpty, unionBy} from 'lodash';

import {paginationStatus, useListPaginator} from '../../../../shared/hooks';
import {
  getSitesBySchedule,
  getAlarmSummaryForSites,
  getSchedulesTemplateBySite,
} from '../../../../api/alarms';
import {filterReducer} from '../filterReducer';
import {
  getConflictingSchedules,
  yyyyMMdd,
  fetchSites,
  checkboxAllStatus,
} from './utils';

const fetchSiteAssignedSchedules = async (
  sspSites,
  selectedSchedule,
  setter,
  isFirstPage,
) => {
  setter((prev) => ({...prev, isLoadingStatus: true}));

  const fetchSiteConflicts = async (
    site,
    limit = 25,
    offset = 0,
    accumulatedConflicts = [],
  ) => {
    try {
      const result = await getSchedulesTemplateBySite(
        site.siteId,
        yyyyMMdd(selectedSchedule.startDate),
        yyyyMMdd(selectedSchedule.endDate),
        limit,
        offset,
      );
      const conflictsForPage = getConflictingSchedules(
        selectedSchedule,
        result.results,
        site.siteName,
      );

      const allConflicts = [...accumulatedConflicts, ...conflictsForPage];
      if (result.results.length < limit) {
        return {
          conflicts: allConflicts,
          siteId: site.siteId,
        };
      }
      return fetchSiteConflicts(site, limit, offset + limit, allConflicts);
    } catch {
      return {
        conflicts: [{name: 'Error'}],
        siteId: site.siteId,
      };
    }
  };

  const results = await Promise.all(
    sspSites.map((site) => fetchSiteConflicts(site)),
  );
  const conflicts = results.filter((c) => !isEmpty(c.conflicts));
  setter((prev) => ({
    conflicts: isFirstPage ? conflicts : [...prev.conflicts, ...conflicts],
    isLoadingStatus: false,
  }));

  return conflicts;
};

const paginationLimit = 25;

/**
 * This hook manages the state and behavior for the site management dialog, which
 * facilitates selecting and assigning templates to sites. It handles data fetching,
 * conflicts, state management, and "select all" logic for a paginated list
 * of sites.
 *
 * @param {Object} selectedSchedule - The template object to be assigned to sites.
 * @param {number} totalSSPSitesCount - The total number of SSP sites available.
 */

export const useSiteManagementDialog = (
  selectedSchedule,
  totalSSPSitesCount,
) => {
  /**
   * selectAllCheckboxStateRef - Tracks the state of the "Select All" checkbox
   * across all pages, including `checked` and `indeterminate` states.
   * Maintains the cached state of all sites, assigned sites,
   * and sites searched within the dialog.
   * Format:
   * {
   *   status: enum <State>,     // Checkbox can have 3 states:checked, unchecked, indeterminate.
   *                             // If some but not all items are selected or it is not possible to determinate if all sites are selected it is indeterminate
   *   searchedSitesRange: [],   // All sites within the current search range
   *   allSites: [],             // All available SSP sites
   *   allAssignedSites: [],     // All sites already assigned to schedules
   * }
   */
  const selectAllCheckboxStateRef = useRef({
    status: checkboxAllStatus.Unchecked,
    searchedSitesRange: [],
    allSites: [],
    allAssignedSites: [],
  });

  /**
   * changesRef - Tracks the changes made during the session. Used for tracking
   * assignments (`set`) and removals (`unset`).
   *
   * Format:
   * {
   *   set: Array,    // Sites marked for assignment
   *   unset: Array,  // Sites marked for removal
   * }
   */
  const changesRef = useRef({
    set: [],
    unset: [],
  });

  /**
   * selectedSites -  A list of currently selected sites including those fetched from the API.
   *
   * Format:
   * {
   *   siteId: String (uuid || mac),
   *   name: Array,
   * }
   */
  const [selectedSites, setSelectedSites] = useState([]);
  const [unprocessableSites, setUnprocessableSites] = useState([]);
  const [conflictedSchedules, setConflictedSchedules] = useState({
    isLoadingStatus: true,
    conflicts: [],
  });
  const [selectAllCheckboxStatus, setSelectAllCheckboxStatus] = useState(
    checkboxAllStatus.Unchecked,
  );

  const [isSelectAllLoading, setIsSelectAllLoading] = useState(false);

  const [{isSearchValueValid, searchValue}, dispatch] = useReducer(
    filterReducer,
    {
      isSearchValueValid: true,
      searchValue: '',
    },
  );

  /**
   * Paginated data handlers using the `useListPaginator` hook:
   *
   * - sspSites: The list of SSP sites for the current page.
   * - sitesBySchedule: The list of sites already assigned to the selected schedule.
   * - onFetchSspSites and onFetchSitesBySchedule: Handlers to fetch paginated data.
   */
  const [
    sspSitesStatus,
    sspSites,
    onFetchSspSites,
    sspSitesCount,
    sspSitesCurrentPage,
  ] = useListPaginator(
    (limit, offset) =>
      fetchSites(getAlarmSummaryForSites, limit, offset, searchValue),
    0,
    paginationLimit,
  );

  const [
    sitesByScheduleStatus,
    sitesBySchedule,
    onFetchSitesBySchedule,
    totalSitesByScheduleCount,
  ] = useListPaginator(
    (limit, offset) =>
      getSitesBySchedule(selectedSchedule.id, searchValue, limit, offset),
    0,
    paginationLimit,
  );

  const isFirstPage = sspSites.length <= paginationLimit;
  const isOnePaged =
    sspSitesCount.current < paginationLimit ||
    sspSites.length === sspSitesCount.current;

  /**
   * handleSelectAll - Handles the "Select All" checkbox behavior for the dialog.
   * Supports paginated and cached data, as well as resolving conflicts.
   *
   * @param {Boolean} checked - Current state of the "Select All" checkbox.
   */
  const handleSelectAll = useCallback(
    async (checked) => {
      /**
       * Handles the selection logic for one-paged list.
       */
      const handleOnePagedSelect = () => {
        const allSites = sspSites.map(({siteId, siteName}) => ({
          siteId,
          name: siteName,
        }));
        const assignedSites = sitesBySchedule.map((id) => ({
          siteId: id,
          name: sspSites.find(({siteId}) => siteId === id).siteName,
        }));
        const notAssignedSites = differenceBy(
          sspSites,
          assignedSites,
          'siteId',
        );

        if (checked) {
          setSelectedSites((prev) => unionBy(prev, allSites, 'siteId'));
          changesRef.current.set = unionBy(
            changesRef.current.set,
            notAssignedSites,
            'siteId',
          );
          changesRef.current.unset = differenceBy(
            changesRef.current.unset,
            assignedSites,
            'siteId',
          );
        } else {
          setSelectedSites((prev) => differenceBy(prev, allSites, 'siteId'));
          changesRef.current.set = differenceBy(
            changesRef.current.set,
            notAssignedSites,
            'siteId',
          );
          changesRef.current.unset = unionBy(
            changesRef.current.unset,
            assignedSites,
            'siteId',
          );
        }
      };

      /**
       * Determines if it's the first time the "Select All" checkbox is clicked.
       */
      const isFirstSelectAllClick =
        isEmpty(selectAllCheckboxStateRef.current.allSites) &&
        isEmpty(selectAllCheckboxStateRef.current.allAssignedSites);

      /**
       * Fetches site data and assigned site data.
       */
      const fetchSiteData = async (totalSitesCount, scheduleId) => {
        if (!searchValue && !isFirstSelectAllClick) {
          return {
            sitesData: selectAllCheckboxStateRef.current.allSites,
            assignedSitesData:
              selectAllCheckboxStateRef.current.allAssignedSites,
          };
        }

        const sites = await fetchSites(
          getAlarmSummaryForSites,
          totalSitesCount,
          0,
          searchValue,
        );

        const sitesData = sites.results.map(({siteId, siteName}) => ({
          siteId,
          name: siteName,
        }));

        const assignedSites = await getSitesBySchedule(
          scheduleId,
          searchValue,
          totalSitesCount,
        );

        const assignedSitesData = assignedSites.results.map((siteId) => {
          const site = sitesData.find((s) => s.siteId === siteId);
          return {siteId, name: site ? site.name : null};
        });

        return {sitesData, assignedSitesData};
      };

      /**
       * Caches all sites and their assigned states for future reference.
       */
      const cacheAllSites = (sitesData, assignedSitesData) => {
        selectAllCheckboxStateRef.current = {
          ...selectAllCheckboxStateRef.current,
          allSites: sitesData,
          allAssignedSites: assignedSitesData,
        };
      };

      /**
       * Resolves the available sites and assignments based on cache and current state.
       */
      const resolveAvailableSites = (sitesData, assignedSitesData) => {
        const availableSites =
          !isEmpty(selectAllCheckboxStateRef.current.allSites) && !searchValue
            ? selectAllCheckboxStateRef.current.allSites
            : sitesData;

        const availableAssignments =
          !isEmpty(selectAllCheckboxStateRef.current.allSites) && !searchValue
            ? selectAllCheckboxStateRef.current.allAssignedSites
            : assignedSitesData;

        return {availableSites, availableAssignments};
      };

      /**
       * Updates the selected sites and tracks the changes based on the checkbox state.
       */
      const updateSelections = (availableSites, availableAssignments) => {
        const newAssignments = availableSites.filter(
          (s) => !availableAssignments.find(({siteId}) => siteId === s.siteId),
        );

        if (!searchValue && !checked) {
          setSelectedSites([]);
          changesRef.current.unset = [...availableAssignments];
          changesRef.current.set = [];
        } else if (searchValue && !checked) {
          // Unselect searched sites
          const searchedSites = availableSites;

          setSelectedSites((prev) =>
            differenceBy(prev, searchedSites, 'siteId'),
          );

          const primaryAssignedSites = searchedSites.filter((site) =>
            availableAssignments.find(({siteId}) => siteId === site.siteId),
          );

          changesRef.current.unset = unionBy(
            changesRef.current.unset,
            primaryAssignedSites,
            'siteId',
          );

          changesRef.current.set = differenceBy(
            changesRef.current.set,
            searchedSites,
            'siteId',
          );
        } else if (searchValue && checked) {
          setSelectedSites((prev) => unionBy(prev, availableSites, 'siteId'));
          selectAllCheckboxStateRef.current.searchedSitesRange = availableSites;
          changesRef.current.set = unionBy(
            changesRef.current.set,
            newAssignments,
            'siteId',
          );

          changesRef.current.unset = differenceBy(
            changesRef.current.unset,
            availableAssignments,
            'siteId',
          );
        } else {
          setSelectedSites(availableSites); // TOCHECK
          changesRef.current.set = checked ? newAssignments : [];
          changesRef.current.unset = checked ? [] : availableAssignments;
        }
      };

      setSelectAllCheckboxStatus(
        checked ? checkboxAllStatus.Checked : checkboxAllStatus.Unchecked,
      );

      if (isOnePaged) {
        handleOnePagedSelect();
        return;
      }

      setIsSelectAllLoading(true);

      const {sitesData, assignedSitesData} = await fetchSiteData(
        totalSSPSitesCount,
        selectedSchedule.id,
      );

      if (isFirstSelectAllClick && !searchValue) {
        cacheAllSites(sitesData, assignedSitesData);
      }

      const {availableSites, availableAssignments} = resolveAvailableSites(
        sitesData,
        assignedSitesData,
      );
      updateSelections(availableSites, availableAssignments);
      setIsSelectAllLoading(false);
    },
    [
      totalSSPSitesCount,
      selectedSchedule.id,
      isOnePaged,
      sspSites,
      sitesBySchedule,
      searchValue,
    ],
  );

  const fetchHandlersRef = useRef({
    onFetchSspSites,
    onFetchSitesBySchedule,
  });

  const statuses = useMemo(
    () => ({
      dataLoading:
        sspSitesStatus === paginationStatus.Loading ||
        sitesByScheduleStatus === paginationStatus.Loading,
      dataFetched: sspSitesStatus === paginationStatus.Terminated,
      dataFetchError:
        sspSitesStatus === paginationStatus.Error ||
        sitesByScheduleStatus === paginationStatus.Error,
      dataReloading:
        sspSitesStatus === paginationStatus.Reloading ||
        sitesByScheduleStatus === paginationStatus.Reloading,
    }),
    [sspSitesStatus, sitesByScheduleStatus],
  );

  useEffect(
    () => {
      fetchHandlersRef.current.onFetchSspSites = onFetchSspSites;
      fetchHandlersRef.current.onFetchSitesBySchedule = onFetchSitesBySchedule;
    },
    [onFetchSspSites, onFetchSitesBySchedule],
  );

  useEffect(
    () => {
      const abortController = new AbortController();

      fetchHandlersRef.current.onFetchSspSites(true, abortController.signal);
      fetchHandlersRef.current.onFetchSitesBySchedule(
        true,
        abortController.signal,
      );

      if (!searchValue) {
        selectAllCheckboxStateRef.current.searchedSitesRange = [];
      }
      return () => {
        abortController.abort();
      };
    },
    [searchValue],
  );

  useEffect(
    () => {
      if (
        selectedSchedule &&
        !statuses.dataLoading &&
        !statuses.dataReloading &&
        !!sspSitesCurrentPage.current.length
      ) {
        fetchSiteAssignedSchedules(
          sspSitesCurrentPage.current,
          selectedSchedule,
          setConflictedSchedules,
          isFirstPage,
        );
      }
    },
    [sspSitesCurrentPage, selectedSchedule, statuses, isFirstPage],
  );

  useEffect(
    () => {
      if (sitesBySchedule.length) {
        const {set, unset} = changesRef.current;
        const combinedChanges = [
          ...unionBy(
            sitesBySchedule.map((siteId) => ({
              siteId,
              name: sspSites.find((s) => s.siteId === siteId)?.siteName,
            })),
            set,
            'siteId',
          ).filter((el) => !unset.find(({siteId}) => siteId === el.siteId)),
        ];
        setSelectedSites(combinedChanges);
      }
    },
    [sitesBySchedule, sspSites, searchValue, conflictedSchedules.conflicts],
  );

  const siteList = useMemo(
    () =>
      sspSites.map((s) => {
        const {siteId} = s;
        const conflicts =
          conflictedSchedules.conflicts
            .find((cs) => cs.siteId === siteId)
            ?.conflicts.map((r) => r.name) || [];
        const selected =
          selectedSites?.some((ss) => ss.siteId === siteId) &&
          !conflicts.length;
        const disabled =
          !!conflicts.length ||
          conflictedSchedules.isLoadingStatus ||
          isSelectAllLoading;
        const additionalInfo = (
          <Typography variant="body2">
            {conflicts[0] === 'Error'
              ? 'Failed to fetch conflicting schedules for this site.'
              : `Conflict: ${conflicts?.join(
                  ', ',
                )} already assigned to this date.`}
          </Typography>
        );

        return {
          type: 'site',
          name: s.siteName,
          id: s.siteId,
          selected,
          disabled,
          ...(conflicts.length && {additionalInfo}),
        };
      }),
    [
      selectedSites,
      sspSites,
      conflictedSchedules.conflicts,
      conflictedSchedules.isLoadingStatus,
      isSelectAllLoading,
    ],
  );

  const disabledSubmit =
    (isEmpty(changesRef.current.set) && isEmpty(changesRef.current.unset)) ||
    statuses.dataReloading ||
    unprocessableSites.length > 0;

  const handleLoadMoreData = () => {
    onFetchSspSites(false);
    if (sitesBySchedule.length !== totalSitesByScheduleCount.current) {
      onFetchSitesBySchedule(false);
    }
  };

  /**
   * handleCheckboxSelect - Handles individual site checkbox selection.
   *
   * @param {Object} site - The selected site object.
   * @param {boolean} checked - The checkbox state (selected/deselected).
   */
  const handleCheckboxSelect = useCallback(
    (site, checked) => {
      setUnprocessableSites([]);
      setSelectedSites((prev) => {
        return checked
          ? unionBy(prev, [{siteId: site.id, name: site.name}], 'siteId')
          : differenceBy(prev, [{siteId: site.id, name: site.name}], 'siteId');
      });

      const isAlreadyAssigned = sitesBySchedule.includes(site.id);

      if (checked) {
        if (!isAlreadyAssigned) {
          changesRef.current.set = unionBy(
            changesRef.current.set,
            [{siteId: site.id, name: site.name}],
            'siteId',
          );
        } else {
          changesRef.current.unset = differenceBy(
            changesRef.current.unset,
            [{siteId: site.id, name: site.name}],
            'siteId',
          );
        }
      } else if (isAlreadyAssigned) {
        changesRef.current.unset = unionBy(
          changesRef.current.unset,
          [{siteId: site.id, name: site.name}],
          'siteId',
        );
      } else {
        changesRef.current.set = differenceBy(
          changesRef.current.set,
          [{siteId: site.id, name: site.name}],
          'siteId',
        );
      }
    },
    [sitesBySchedule],
  );

  const calculateSelectAllCheckboxStatus = useCallback(
    () => {
      if (sspSites.length) {
        let isChecked = false;
        let isIndeterminate = false;

        const isAllSelected = sspSitesCount.current === selectedSites.length;

        const totalAssignable =
          sspSitesCount.current - conflictedSchedules.conflicts.length;
        const totalSelected = sspSites.filter(({siteId}) =>
          siteList.find(({id, selected}) => selected && id === siteId),
        ).length;

        if (isOnePaged && totalAssignable === 0) {
          isChecked = false;
          isIndeterminate = false;
        } else if (isOnePaged) {
          isChecked = totalSelected === totalAssignable;
          isIndeterminate =
            totalSelected > 0 && totalSelected < totalAssignable;
        } else if (!searchValue) {
          isChecked = isAllSelected;
          isIndeterminate =
            selectedSites.length > 0 &&
            selectedSites.length < sspSitesCount.current;
        } else {
          const selectedInSearchCount = selectedSites.filter(
            ({siteId}) =>
              selectAllCheckboxStateRef.current.allSites.find(
                (s) =>
                  s.siteId === siteId &&
                  s.name.toLowerCase().includes(searchValue.toLowerCase()),
              ) ||
              selectAllCheckboxStateRef.current.allAssignedSites.find(
                (s) =>
                  s.siteId === siteId &&
                  s.name.toLowerCase().includes(searchValue.toLowerCase()),
              ) ||
              selectAllCheckboxStateRef.current.searchedSitesRange.find(
                (ss) => ss.siteId === siteId,
              ) ||
              sspSites.find((site) => site.siteId === siteId) ||
              sitesBySchedule.find((id) => id === siteId),
          ).length;

          isChecked = selectedInSearchCount === sspSitesCount.current;
          isIndeterminate =
            selectedInSearchCount > 0 &&
            selectedInSearchCount < sspSitesCount.current;
        }
        if (isChecked) {
          return checkboxAllStatus.Checked;
        }
        if (isIndeterminate) {
          return checkboxAllStatus.Indeterminate;
        }
        return checkboxAllStatus.Unchecked;
      }
      return checkboxAllStatus.Unchecked;
    },
    [
      conflictedSchedules.conflicts.length,
      isOnePaged,
      searchValue,
      selectedSites,
      siteList,
      sitesBySchedule,
      sspSites,
      sspSitesCount,
    ],
  );

  /**
   * handling state of 'Select All' checkbox -->
   */
  useEffect(
    () => {
      setSelectAllCheckboxStatus(calculateSelectAllCheckboxStatus());
    },
    [calculateSelectAllCheckboxStatus],
  );

  /**
   * resetState - Resets the state of the dialog, clearing all selections and changes.
   */
  const resetState = () => {
    setSelectedSites([]);
    selectAllCheckboxStateRef.current = {checked: false, indeterminate: false};
    changesRef.current = {
      set: [],
      unset: [],
    };
    setUnprocessableSites([]);
  };

  return {
    siteList,
    handleLoadMoreData,
    selectedSites,
    disabledSubmit,
    handleCheckboxSelect,
    handleSelectAll,
    resetState,
    searchValue,
    isSearchValueValid,
    dispatch,
    statuses,
    unprocessableSites,
    setUnprocessableSites,
    conflictedSchedules,
    isFirstPage,
    isSelectAllLoading,
    isOnePaged,
    changesRef,
    selectAllCheckboxStatus,
  };
};
