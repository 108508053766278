import React, {useCallback} from 'react';
import {Grid, Box} from '@mui/material';
import capitalize from 'lodash/capitalize';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import {getSafetyButtonsSummaryForSites} from '../../../../api/alarms';
import ButtonModeChip from './ButtonModeChip';

const columnDefinitions = [
  {
    id: 'siteName',
    label: 'Site name',
  },
  {
    id: 'mode',
    label: 'Button mode',
    notSortable: true,
  },
];

const initialOrderOptions = {
  initialOrderBy: 'siteName',
  initialOrder: 'asc',
};

const modeToLabel = (mode) =>
  mode === 'eventsOnly' ? 'Events Only' : capitalize(mode);

/**
 * Generates an array of ButtonModeChip components based on the modes and counts provided in the buttons object.
 * If all buttons are active, it returns a single chip with the label "All Active".
 *
 * @param {Object} buttons - An object where keys are button modes and values are the count of buttons in each mode.
 * @returns {Array} - An array of ButtonModeChip components.
 */
const buttonsModes = (buttons) => {
  const buttonModes = ['active', 'eventsOnly', 'test', 'inactive'];
  const totalButtons = buttonModes.reduce(
    (total, mode) => total + (buttons[mode] || 0),
    0,
  );

  if (buttons.active === totalButtons) {
    return [
      <ButtonModeChip key="active" mode="active" customLabel="All Active" />,
    ];
  }

  return buttonModes
    .filter((mode) => buttons[mode])
    .map((mode) => (
      <ButtonModeChip
        key={mode}
        mode={mode}
        customLabel={`${buttons[mode]} ${modeToLabel(mode)}`}
      />
    ));
};

const ConfigurationSection = () => {
  const onLoadData = useCallback(
    async (page, limit, sortingOrder, sortingColumn) => {
      const data = await getSafetyButtonsSummaryForSites(
        page * limit,
        limit,
        sortingOrder,
        sortingColumn,
      );
      return {
        count: data.count,
        results: data.results.map((result) => ({
          ...result,
          mode: (
            <Box sx={{display: 'flex', gap: 1}}>
              {buttonsModes(result.buttons)}
            </Box>
          ),
        })),
      };
    },
    [],
  );

  return (
    <Grid data-cy="sites-table" item xs={12} display="grid">
      <CollapsibleTable
        rowId="siteId"
        columns={columnDefinitions}
        sortable
        orderOptions={initialOrderOptions}
        onLoadData={onLoadData}
        paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
        stickyHeader={false}
      />
    </Grid>
  );
};

ConfigurationSection.propTypes = {};

export default ConfigurationSection;
