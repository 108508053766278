import React from 'react';

import {Route, Switch, Redirect} from 'react-router-dom';
import GlobalErrorPage from '../globalErrorPage';
import {BI_REPORT_2} from '../../shared/util/allowed';
import PageLoader from '../../shared/components/pageLoader';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import {getReportData, getSiteHierarchyData} from '../../api/reports';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {getBiAlerts} from '../../api/users';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import {getPartners} from '../../api/domains';

const Loader = ({match, currentUser}) => {
  let pillar;
  if (isNewNavigationWebEnabled(currentUser)) {
    pillar = match.url.replace('/', '');
  }

  return (
    <Switch>
      {/* Reroute old report links to new nav urls if newNav is enabled */}
      {isNewNavigationWebEnabled(currentUser) && (
        <Route
          path="/reports/:rest"
          render={(renderProps) => {
            const {rest} = renderProps.match.params;
            if (rest.includes('ur') || rest.includes('br')) {
              return (
                <Redirect
                  to={{
                    pathname: `/loss-prevention/reports/${rest}`,
                    search: renderProps.location.search,
                  }}
                />
              );
            }
            return <Redirect to={`/loss-prevention/${rest}`} />;
          }}
        />
      )}

      {/* NEW REPORTS PAGE */}
      {currentUser.settings.enableNewReportsDetailPage &&
        !isNewNavigationWebEnabled(currentUser) && (
          <Route
            path={`${match.path}/:reportId`}
            render={(renderProps) => {
              const {reportId} = renderProps.match.params;
              return (
                <CapabilitiesGuard
                  allowed={[BI_REPORT_2]}
                  renderDefault={() => <GlobalErrorPage error="NoAuth" />}
                >
                  <PageLoader
                    page={() => import('./newReportsPage/Page')}
                    resources={{
                      reportId: () => reportId,
                      reportData: () => getReportData(reportId),
                      siteHierarchyData: () => getSiteHierarchyData(),
                    }}
                  />
                </CapabilitiesGuard>
              );
            }}
          />
        )}

      <CapabilitiesGuard
        allowed={[BI_REPORT_2]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <PageLoader
          page={() => import('./Page')}
          resources={{
            alerts: () => getBiAlerts(currentUser.id),
            partners: () => getPartners(currentUser.domainId),
          }}
          pillar={pillar}
        />
      </CapabilitiesGuard>
    </Switch>
  );
};

export default withCurrentUser(Loader);
