import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const AccessDeniedDialog = ({
  accessDeniedDialogOpen,
  handleAccessDeniedDialogClose,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      PaperProps={{sx: {height: '25vh'}}}
      data-cy="access-denied-error"
      open={accessDeniedDialogOpen}
      onClose={handleAccessDeniedDialogClose}
    >
      <DialogTitle
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        Access Denied
      </DialogTitle>
      <DialogContent sx={{height: '100%', overflow: 'auto'}}>
        <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
          There are sites assigned to this template that you have no access to.
        </Typography>
        <br />
        <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
          You can&apos;t Edit it
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleAccessDeniedDialogClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccessDeniedDialog.propTypes = {
  accessDeniedDialogOpen: PropTypes.bool.isRequired,
  handleAccessDeniedDialogClose: PropTypes.func.isRequired,
};

export default AccessDeniedDialog;
