import {useState, useCallback, useRef, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import set from 'lodash/set';
import {getSites} from '../api/sites';
import {flattenSitesListForSearch} from './util/sites';

// Custom hook for maintaining dialog open/close state
export const useDialog = (defaultOpen = false) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleOpen = useCallback(
    () => {
      setOpen(true);
    },
    [setOpen],
  );

  const handleClose = useCallback(
    () => {
      setOpen(false);
    },
    [setOpen],
  );

  return [open, handleOpen, handleClose];
};

// Custom hook for dropdown selector state management
export const useSelector = (
  initialValue,
  property = 'name',
  separator = ',',
) => {
  const [filterValues, setFilterValues] = useState(initialValue);

  const setAdaptedValues = (values) => {
    const queryValues = values.map((val) => val[property]).join(separator);
    setFilterValues(queryValues);
  };

  return [filterValues, setAdaptedValues];
};

export const useInterval = (callback, delay, dependencies = []) => {
  const savedCallback = useRef();

  // Remember the latest callback;
  useEffect(
    () => {
      savedCallback.current = callback;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...dependencies],
  );

  // Set up the interval
  useEffect(
    () => {
      function tick(id) {
        savedCallback.current(id);
      }

      const id = setInterval(() => tick(id), delay);
      // interval cleared on unmount
      return () => clearInterval(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay, ...dependencies],
  );
};

/**
 * A custom hook that sets a timeout to execute a callback function after a specified delay.
 * The timeout is canceled if the `shouldCancel` parameter is `true` or when the component unmounts.
 * The timer is also reset when the dependencies change.
 *
 * @param {Function} callback - The callback function to be executed after the delay.
 * @param {number} delay - The delay in milliseconds before executing the callback. If `null`, no timeout is set.
 * @param {boolean} [shouldCancel=false] - If `true`, the timeout is canceled and the callback will not be executed.
 * @param {Array} [dependencies=[]] - An array of dependencies that will reset the timer if they change.
 *
 * @example
 * // Usage example:
 * useTimeout(() => {
 *   console.log('This will run after 3 seconds unless canceled or dependencies change.');
 * }, 3000, false, [dependency1, dependency2]);
 */
export const useTimeout = (
  callback,
  delay,
  shouldCancel = false,
  dependencies = [],
) => {
  const savedCallback = useRef();

  useEffect(
    () => {
      savedCallback.current = callback;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...dependencies],
  );

  useEffect(
    () => {
      if (shouldCancel) {
        return;
      }

      const id = setTimeout(() => {
        savedCallback.current();
      }, delay);

      // Clear the timeout if the component unmounts or dependencies change
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delay, ...dependencies],
  );
};

// pagination hook statuses
export const paginationStatus = Object.freeze({
  Idle: 'Idle',
  Loading: 'Loading', // fetching more data (offset > 0)
  Reloading: 'Reloading', // re-fetching data (offset = 0)
  Error: 'Error',
  Terminated: 'Terminated', // all entries have already been fetched
});
// custom hook for pagination
export const useListPaginator = (
  apiHandler,
  initPage = 0,
  paginationLimit = 50,
) => {
  const totalCount = useRef(null);
  const pageRef = useRef(initPage);
  const allDataFetched = useRef(false);
  const currentPageDataRef = useRef([]);

  const [listData, setListData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(paginationStatus.Idle);

  const onLoadMoreData = useCallback(
    async (resetData = false, abortControllerSignal) => {
      // further execution prohibited in following state(s)
      if (currentStatus === paginationStatus.Loading) {
        return;
      }

      if (resetData) {
        pageRef.current = 0;
        allDataFetched.current = false;
        setCurrentStatus(paginationStatus.Reloading);
      } else setCurrentStatus(paginationStatus.Loading);

      try {
        const newData = await apiHandler(
          paginationLimit,
          pageRef.current * paginationLimit,
          abortControllerSignal,
        );

        if (newData) {
          const {results, count} = newData;
          currentPageDataRef.current = results;
          totalCount.current = count;
          setListData((currentList) => {
            const updatedList = resetData
              ? results
              : [...currentList, ...results];
            if (
              updatedList.length === count ||
              results.length < paginationLimit
            )
              allDataFetched.current = true;
            else pageRef.current += 1;
            return updatedList;
          });
          setCurrentStatus(
            allDataFetched.current
              ? paginationStatus.Terminated
              : paginationStatus.Idle,
          );
        }
      } catch (error) {
        if (error.message !== 'canceled') {
          setCurrentStatus(paginationStatus.Error);
        }
      }
    },
    [apiHandler, currentStatus, paginationLimit],
  );

  return [
    currentStatus,
    listData,
    onLoadMoreData,
    totalCount,
    currentPageDataRef,
  ];
};

export const useValidation = (schema) => {
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(true);

  const validate = useCallback(
    (obj) => {
      let v = true;
      let e = {};
      try {
        schema.validateSync(obj, {abortEarly: false});
      } catch (err) {
        const validationErrors = err.inner.reduce((acc, error) => {
          const [fieldError] = error.errors;
          set(acc, error.path, fieldError);
          return acc;
        }, {});
        v = false;
        e = validationErrors;
      }
      setValid(v);
      setErrors(e);
      return {
        valid: v,
        errors: e,
      };
    },
    [schema, setErrors],
  );

  return [valid, errors, validate];
};

export const useApi = (apiFn) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const call = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        const result = await apiFn(...args);
        setData(result);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [apiFn],
  );

  return [data, loading, error, call];
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

// according to mobile guidelines - we should not have more than 5 items in the bottom navigation bar
const MAX_BOTTOM_NAV_BAR_ITEMS = 5;
// for handling sidebar navigation on the responsive page template
export const useNavigation = (
  navigationItems = [],
  moreButtonDefinition = {
    path: [],
    capabilities: [],
    id: 'more-button',
    name: 'more-button',
    icon: null,
    primaryText: 'More',
  },
  bottom = false,
  navigationState = {
    open: false,
    anchorEl: null,
    subNavItems: null,
    hoveredMainItem: null,
  },
  selectedItemState = {name: null, secondaryText: null},
  customIsActiveMatcher,
  closingTimeout = 1000,
) => {
  const {pathname} = useLocation();
  const [navItems, setNavItems] = useState(navigationItems);
  const shouldRenderMoreButton =
    bottom && navigationItems.length > MAX_BOTTOM_NAV_BAR_ITEMS;

  const [subNavigationState, setSubNavigationState] = useState(navigationState);
  const [selectedMainItemState, setSelectedMainItemState] = useState(
    selectedItemState,
  );
  const closeTimeoutRef = useRef(null);
  const clearCloseTimeout = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
  };

  const openSubNav = (e, items, itemName) => {
    clearCloseTimeout();
    if (items) {
      setSubNavigationState({
        open: true,
        anchorEl: e.currentTarget,
        subNavItems: items,
        hoveredMainItem: itemName,
      });
    }
  };

  const openSubBottomNav = (items, itemName) =>
    setSubNavigationState((prev) => ({
      anchorEl: prev.anchorEl,
      open: true,
      subNavItems: items,
      hoveredMainItem: itemName,
    }));

  const clickSubNavItem = (text, name) => {
    setSelectedMainItemState({name, secondaryText: text});
    setSubNavigationState((prev) => ({
      open: bottom,
      anchorEl: bottom ? prev.anchorEl : null,
      subNavItems: bottom ? prev.subNavItems : null,
      hoveredMainItem: null,
    }));
  };

  const clickMainNavItem = () =>
    setSelectedMainItemState((prev) => ({
      ...prev,
      secondaryText: subNavigationState.subNavItems ? prev.secondaryText : null,
    }));

  const mouseEnter = () => clearCloseTimeout();

  const mouseLeave = () => {
    clearCloseTimeout();
    closeTimeoutRef.current = setTimeout(() => {
      setSubNavigationState({
        open: false,
        anchorEl: null,
        subNavItems: null,
        hoveredMainItem: null,
      });
    }, closingTimeout);
  };

  const closeSubNav = () => {
    clearCloseTimeout();
    setSubNavigationState((prev) => ({
      open: false,
      anchorEl: bottom ? prev.anchorEl : null,
      subNavItems: null,
      hoveredMainItem: null,
    }));
  };

  // this useEffect cover Report page case , when open reports with the old urls on the new navigation.
  // customIsActiveMatcher function is defined in the reports/Page.jsx
  useEffect(
    () => {
      if (!customIsActiveMatcher || !navigationItems) return;

      const selectedItem = navigationItems.find((item) => {
        const basePath = `/${item.name}/`;
        return item.subItems?.some((subItem) =>
          customIsActiveMatcher(`${basePath}${subItem.path}`, pathname, true),
        );
      });

      if (selectedItem) {
        const basePath = `/${selectedItem.name}/`;
        const selectedSubItem = selectedItem.subItems.find((subItem) =>
          customIsActiveMatcher(`${basePath}${subItem.path}`, pathname, true),
        );

        if (selectedSubItem) {
          setSelectedMainItemState({
            name: selectedItem.name,
            secondaryText: selectedSubItem.label,
          });
        }
      }
    },
    [navigationItems, pathname, customIsActiveMatcher],
  );

  /* eslint-disable no-param-reassign */
  useEffect(() => {
    if (shouldRenderMoreButton) {
      setNavItems((prev) => {
        const mainItems = prev.slice(0, MAX_BOTTOM_NAV_BAR_ITEMS - 1);
        let rest = prev
          .slice(MAX_BOTTOM_NAV_BAR_ITEMS - 1)
          .map((r) => ({...r, name: moreButtonDefinition.name}))
          .reverse();
        if (rest.some((r) => r.subItems)) {
          // eslint-disable-next-line no-console
          console.warn(`
          Dear developer, you are trying to include an item with sub-items in the collapsed "More" section.
          Currently, we do not support nested popover menus and this item will be filtered out.
          Kindly relocate this item within the first ${MAX_BOTTOM_NAV_BAR_ITEMS -
            1} positions in the navigation bar.
          For further guidance, please refer to the Storybook documentation.`);
          rest = rest.filter((r) => !r.subItems);
        }
        moreButtonDefinition.path = rest.map((r) => r.path);
        moreButtonDefinition.subItems = rest;
        return [...mainItems, moreButtonDefinition];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* eslint-enable no-param-reassign */

  useEffect(
    () => {
      if (subNavigationState.anchorEl?.current !== null) {
        setSubNavigationState((prev) => ({
          ...prev,
          anchorEl: subNavigationState.anchorEl,
        }));
      }
      return () => {
        if (!bottom) clearCloseTimeout();
      };
    },
    [bottom, subNavigationState.anchorEl],
  );

  return [
    navItems,
    subNavigationState,
    selectedMainItemState,
    openSubNav,
    openSubBottomNav,
    closeSubNav,
    clickSubNavItem,
    clickMainNavItem,
    mouseEnter,
    mouseLeave,
    setSelectedMainItemState,
  ];
};

// to handle click out
export const useOnClickOutside = (ref, anchorRef, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          (anchorRef.current && anchorRef.current.contains(event.target))
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, anchorRef, handler],
  );
};

/**
 * Custom hook that can be used together with SiteSelectionMenu component.
 *
 * @param {urlState}, @param {setUrlState}   - Those params are needed when siteId comes from the query param.
 * When it comes from path param then result from useParams hook is used.
 */

const updatePathParam = (
  currentPath,
  paramKey,
  paramValue,
  optionalSegments = [],
) => {
  let updatedPath = currentPath.replace(
    new RegExp(`/${paramKey}/[^/]+`),
    `/${paramKey}/${paramValue}`,
  );
  optionalSegments.forEach((segment) => {
    updatedPath = updatedPath.replace(segment, '');
  });

  return updatedPath;
};

export const useSiteSelector = (
  urlState = {siteId: ''},
  setUrlState,
  history,
  site,
  fetchedSites,
) => {
  const {siteId} = useParams();
  const [sites, setSites] = useState(fetchedSites);
  const [selectedSite, setSelectedSite] = useState({
    id: urlState.siteId || siteId || '',
    name: site?.name || '',
  });
  const [reload, setReload] = useState(null);

  const handleUpdatePathParam = (newSiteId) => {
    const currentPath = window.location.pathname;
    const updatedPath = updatePathParam(currentPath, 'sites', newSiteId, [
      '/ui',
    ]);
    history.push(updatedPath);
  };

  const handleSiteSelect = async (e) => {
    if (siteId) {
      handleUpdatePathParam(e.currentSite);
    }
    setSelectedSite({
      id: e.currentSite,
      name: e.name,
    });
    if (setUrlState) {
      setReload(new Date());
    }
  };

  useEffect(
    () => {
      const handleGetSites = async () => {
        const sitesList = await getSites();
        setSites(sitesList);
      };
      if (!fetchedSites) {
        handleGetSites();
      }
    },
    [fetchedSites],
  );

  useEffect(
    () => {
      const handleSelectedSite = () => {
        const siteToLoad = flattenSitesListForSearch(sites, sites?.name).find(
          (s) => s.id === urlState.siteId || s.id === siteId,
        );
        setSelectedSite({
          id: urlState.siteId || siteId,
          name: siteToLoad?.name,
        });
      };
      handleSelectedSite();
    },
    [sites, urlState.siteId, siteId],
  );

  useEffect(
    () => {
      if (setUrlState) {
        setUrlState({siteId: selectedSite.id, alarmId: undefined});
      }
    },
    [selectedSite.id, setUrlState],
  );

  return [selectedSite, sites, reload, handleSiteSelect];
};

/**
 * Custom hook that implements a capped exponential backoff for repeated calls to an API or function.
 *
 * @param {Function} callback - The function to be called repeatedly.
 * @param {number} initialDelay - The initial delay in milliseconds before the first call.
 * @param {number} maxDelay - The maximum delay in milliseconds between calls.
 * @param {boolean} ready - Flag to determine whether the hook should start executing the callback.
 * @returns {Function} A function to reset the delay to its initial value.
 *
 * @Example
 * const resetDelay = useExponentialBackoff(myFunc, 1000, 15000, resultsBool, readyForPollingBool);
 * @using reset:  The resetDelay can be called to clear the timer and reset the polling process.
 */
export const useExponentialBackoff = (
  callback,
  initialDelay,
  maxDelay,
  ready,
) => {
  const savedCallback = useRef();
  const delay = useRef();
  const timeoutId = useRef();

  // Remember the latest callback
  useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback],
  );

  // Set up the exponential backoff
  useEffect(
    () => {
      // Wrapper function to execute the callback and schedule the next call
      const tick = () => {
        savedCallback.current();
        // Calculate the next delay with an exponential increase, capped at maxDelay
        const nextDelay = Math.min(delay.current * 2, maxDelay);
        delay.current = nextDelay;
        timeoutId.current = setTimeout(tick, nextDelay); // Set the next timeout
      };
      // Start the first timeout
      if (ready && delay.current === initialDelay) {
        timeoutId.current = setTimeout(tick, 0);
      }

      // Cleanup function to clear the timeout on unmount or when dependencies change
      return () => clearTimeout(timeoutId.current);
    },
    [initialDelay, maxDelay, ready],
  );

  // Function to reset the delay (e.g., before the start of a new operation)
  const reset = useCallback(
    () => {
      delay.current = initialDelay;
      clearTimeout(timeoutId.current);
    },
    [initialDelay],
  );

  // Return the reset function so it can be called from the component
  return reset;
};
